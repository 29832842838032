<template lang="html">
  <div class="row">

    <div class="col-lg-8 col-md-8 col-sm-12">
      <div class="card">
        <div class="card-body form-card">

          <div class="form-group row">
            <div class="col-2">
              <label class="required">Tên form</label>
            </div>
            <div class="col-10">
              <el-input
                type="text"
                placeholder="Tên form"
                v-model="form.name"
                :class="errors.has('name')?'border-danger':''"
                v-validate="'required'"
                data-vv-name="name"
                data-vv-as="Tên form"
              ></el-input>
              <span class="text-danger" v-if="errors.has('name')">{{ errors.first('name') }}</span>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-2">
              <label class="required">Tiêu đề</label>
            </div>
            <div class="col-10">
              <el-input
                type="text"
                placeholder="Tiêu đề mail"
                v-model="form.mail_title"
                :class="errors.has('mail_title')?'border-danger':''"
                v-validate="'required'"
                data-vv-name="mail_title"
                data-vv-as="Tiêu đề"
              ></el-input>
              <span class="text-danger" v-if="errors.has('mail_title')">{{ errors.first('mail_title') }}</span>
            </div>
          </div>

          <div class="form-group row">
            <div class="col-2">
              <label class="required">Nội dung</label>
            </div>
            <div class="col-10">
              <my-editor
                v-model="form.mail_content"
                apiKey="pgm9gcnllfimfcgo7joyxpm22j4ov87erg4u4fyy7gxo1g23"
                :class="errors.has('mail_content')?'border-danger':''"
                v-validate="'required'"
                data-vv-name="mail_content"
                data-vv-as="Nội dung mail">
              </my-editor>
              <span class="text-danger" v-if="errors.has('mail_content')">{{ errors.first('mail_content') }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-lg-4 col-md-4 col-sm-12 sidebar-right">
      <div class="card">
        <div class="card-body form-card">
          <div class="form-group">
            <label>Danh sách biến</label>
            <div class="col-12">
              <ul style="padding-left: 0;">
                <li v-for="item in variables"><code>{{ item }}:</code> {{ hashTitles[item] }}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="col-sm-12">
      <div class="card">
        <div class="card-body form-card">

          <div class="col-sm-12">
            <div class="row">
              <p><b>Lọc địa chỉ email</b></p>
            </div>

            <div class="filter-email">
              <div class="row">
                <div class="col-sm-6" style="padding-left: 0;">

                  <el-select v-model="form.race_id" clearable placeholder="Sự kiện">
                    <el-option
                      v-for="(race, index) in races"
                      :key="index"
                      :label="race.title"
                      :value="race.id">
                    </el-option>
                  </el-select>

                  <el-select v-model="form.sort_by" clearable placeholder="Sắp xếp">
                    <el-option v-for="(item, key) in sort_by" :label="item" :value="key" :key="key"></el-option>
                  </el-select>

                  <el-select v-model="form.race_status" clearable placeholder="Trạng thái">
                    <el-option v-for="(item, key) in race_status" :label="item" :value="key" :key="key"></el-option>
                  </el-select>

                  <div class="row" style="margin-bottom: 10px;">
                    <div class="col-6">
                      <el-input placeholder="Số thứ tự" v-model="form.index_start" type="number">
                        <template slot="prepend">Từ</template>
                      </el-input>
                    </div>
                    <div class="col-6">
                      <el-input placeholder="Số thứ tự" v-model="form.index_end" type="number">
                        <template slot="prepend">Đến</template>
                      </el-input>
                    </div>
                  </div>

                  <el-select v-model="form.gender" clearable placeholder="Giới tính">
                    <el-option
                      label="Nam"
                      :value="1">
                    </el-option>
                    <el-option
                      label="Nữ"
                      :value="0">
                    </el-option>
                  </el-select>

                </div>

                <div class="col-sm-6">
                  <el-select v-model="form.email_status" clearable placeholder="Email status">
                    <el-option
                      v-for="(email, index) in email_status"
                      :key="index"
                      :label="email"
                      :value="email">
                    </el-option>
                  </el-select>
                  <el-input placeholder="Nhập tên miền" v-model="form.domain"></el-input>
                  <el-input type="textarea" :rows="8" placeholder="Nhập danh sách email test (mỗi email 1 dòng)" v-model="form.test_emails"></el-input>
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-12">
            <div class="row">
              <p><b>Lọc địa chỉ email</b></p>
            </div>
            <div class="filter-email">
              <div class="row">
                <div class="col-sm-6" style="padding-left: 0;">
                  <el-select v-model="form.prevent_join_race_id" clearable placeholder="User đã tham gia sự kiện" :multiple="true">
                    <el-option
                      v-for="(race, index) in races"
                      :key="index"
                      :label="race.title"
                      :value="race.id">
                    </el-option>
                  </el-select>
                </div>
              </div>
            </div>
          </div>

          <div class="col-sm-12">
            <div class="row">
                <div class="col-sm-3" style="padding-left: 0;">
                  <el-button type="primary" class="btn-search-email" icon="el-icon-search" @click="searchUser">Lọc</el-button>
                </div>
              </div>
              <div class="list-emails" style="margin-top: 30px;">
                <p>
                  <b>Số lượng email: <span>{{ total }}</span></b>
                  <div class="btn-view-list-email"><a v-if="total > 0" @click="isShowListEmail = true;">Xem danh sách email</a></div>
                </p>
                <div v-if="isShowListEmail">
                  <el-tag
                    v-for="(email, index) in emails"
                    :key="index"
                    type="info"
                    closable
                    :disable-transitions="false"
                    @close="removeEmail(email)">
                    {{ email }}
                  </el-tag>
                  <el-input
                    class="input-new-tag"
                    v-if="inputVisible"
                    v-model="inputValue"
                    ref="saveTagInput"
                    size="mini"
                    @keyup.enter.native="handleInputConfirm"
                  >
                  </el-input>
                </div>
                <el-button v-if="isShowListEmail && this.total > this.emails.length"  class="button-new-tag btn-view-more" type="primary" size="small" @click="viewMoreEmail">Xem thêm</el-button>
                <div class="hidden">
                  <hr>
                  <el-button class="button-new-tag" size="small" @click="showInput">+ Nhập email</el-button>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container-fluid">
      <div class="page-action">
        <el-button @click="cancel">Quay lại</el-button>
        <el-button type="primary" @click="save">Lưu lại</el-button>
      </div>
    </div>
    <loading :active.sync="isLoading"></loading>
  </div>
</template>

<script>

import { Input, Button, Message, Select, Option, Tag, MessageBox } from 'element-ui';
import MyEditor from 'src/components/UIComponents/Editor'
import MySelect from 'src/components/UIComponents/Select'
import { mapState } from 'vuex'
import { mapActions } from 'vuex'
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';

export default {
  components: {
    ElInput: Input,
    ElButton: Button,
    ElSelect: Select,
    ElOption: Option,
    ElTag: Tag,
    MyEditor,
    MySelect,
    Message,
    Loading
  },

  data() {
    return {
      form: {},
      variables: [],
      hashTitles: {},
      inputVisible: false,
      inputValue: '',
      isLoading: false,
      isShowListEmail: false,
      email_status: ['confirmed', 'valid', 'invalid'],
      race_status: {
        register_fee: 'Đăng ký có phí',
        register_free: 'Đăng ký miễn phí',
        completed: 'Hoàn thành',
        joined: 'Chưa hoàn thành'
      },
      sort_by: {
        created_at: 'Đăng ký sớm',
        completed_at: 'Hoàn thành sớm'
      },
      total: 0,
      emails: [],
      page: 1
    }
  },

  computed: {
    mailTypes() {
      return this.$store.state.mailTypes;
    },
    races() {
      let data = this.$store.state.shortRaces || [];
      return data;
    }
  },

  mounted() {
    this.$store.dispatch('fetchMailTypes');
    this.$store.dispatch('setPageTitle', 'Tạo form gửi mail');
    this.$store.dispatch('fetchMailTypes');
    this.$store.dispatch('fetchRacesShort');
    this.$store.dispatch('setCurrentActions', [{
      label: 'Lưu lại',
      type: 'primary',
      icon: '',
      callback: this.save
    }])
  },

  methods: {
    removeEmail(email) {
      this.emails.splice(this.emails.indexOf(email), 1);
    },
    showInput() {
      this.inputVisible = true;
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },
    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        if (!this.$util.validateEmail(inputValue)) {
          return Message({
            message: 'Email không đúng định dạng',
            type: 'error'
          });
        }
        this.emails.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = '';
    },

    searchUser() {
      this.page = 1;
      this.emails = [];
      this.total = 0;
      this.isShowListEmail = false;
      this.filterUserEmail();
    },

    viewMoreEmail() {
      this.page = this.page + 1;
      this.filterUserEmail();
    },

    filterUserEmail() {
      let obj = {
        race_id: this.form.race_id,
        sort_by: this.form.sort_by,
        race_status: this.form.race_status,
        gender: this.form.gender,
        email_status: this.form.email_status,
        domain: this.form.domain,
        test_emails: this.form.test_emails,
        page: this.page,
        prevent_join_race_id: this.form.prevent_join_race_id,
        index_start: this.form.index_start,
        index_end: this.form.index_end
      };

      if (obj.prevent_join_race_id && obj.prevent_join_race_id.length) {
        obj.prevent_join_race_id = obj.prevent_join_race_id.join(',');
      }

      this.isLoading = true;
      this.$store.dispatch('searchUserEmail', obj).then(res => {
        if (res.success) {
          this.total = res.total;
          if (res.total > 0) {
            this.emails = this.emails.concat(res.emails);
          } else {
            Message({
              message: 'Không có dữ liệu',
              type: 'error'
            });
          }
        }
        setTimeout(_ => {
          this.isLoading = false;
        }, 200);
      });
    },
    cancel() {
      this.$router.push({name: 'AllMailForms'});
    },
    save() {
      let self = this;
      this.$validator.validateAll().then(result => {
        if (!result) return;
        self.$store.dispatch('storeMailForm', self.form).then(res => {
          if (res.success) {
            Message({
              message: 'Tạo mới thành công',
              type: 'success'
            });
            setTimeout(() => {
              self.$router.push('/mail/forms/' + res.data.id);
            }, 1500);
          } else {
            if (res.errors) {
              for (let err in res.errors) {
                Message({
                  message: res.errors[err][0],
                  type: 'error'
                });
              }
            } else {
              Message({
                message: res.message,
                type: 'error'
              });
            }
          }
        }, error => {
          console.error(error);
        });
      })
    }
  },
  watch: {
    mailTypes(newVal) {
      if (newVal && newVal.variables) {
        this.variables = newVal.variables[999];
      }
      if (newVal && newVal.titles) {
        for (let k in newVal.titles) {
          this.hashTitles[k] = newVal.titles[k];
        }
      }
    }
  },
  destroyed() {
    this.$store.dispatch('setCurrentActions', []);
  }
}
</script>

<style lang="scss">

  .navbar-nav {
    .el-button {
      margin-left: 10px !important;
    }
  }

  .filter-email {

    .el-select,
    .el-input {
      width: 100%;
      margin-bottom: 10px;
    }

    .el-button.btn-search-email {
      width: 100%;
    }
  }

  .hidden {
    display: none;
  }

  .list-emails {
    margin-bottom: 10px;
    .el-tag {
      margin-right: 5px;
      margin-bottom: 5px;
      margin-left: 0;
      padding: 0px 6px;
      height: 20px;
      line-height: 20px;
      font-size: 12px;
      border: none;
      border-radius: 5px !important;
    }
    .el-tag + .el-tag {
      margin-right: 5px;
      margin-bottom: 5px;
    }

    .button-new-tag {
      height: 32px;
      line-height: 30px;
      padding-top: 0;
      padding-bottom: 0;
      margin-left: 0;
    }
    .input-new-tag {
      width: 90px;
      vertical-align: bottom;
    }
    .btn-view-more {
      margin-top: 10px;
    }

    .btn-view-list-email {
      cursor: pointer;
      color: rgb(0, 0, 238);
    }
  }

</style>